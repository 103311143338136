import $ from 'jquery';

// -----------------
const $input = $('.js-placeholder-label');
const filledClass = 'is-filled';

const onInputFocus = function() {
    $(this).parent().removeClass(filledClass);
};
const onInputBlur = (input) => {
    if(input.target.value == ''){
        $(input.target).parent().addClass(filledClass);
    }
};

$input.focus(onInputFocus);
$input.blur(onInputBlur);

// ====== Popup ======

const $inputPopup = $('.js-placeholder-label-popup');
const filledClassPopup = 'is-filled';

const onInputFocusPopup = function() {

    $(this).parent().addClass(filledClassPopup);
}
const onInputBlurPopup = (input) => {
    if(input.target.value == ''){
        $(input.target).parent().removeClass(filledClassPopup);
    }
}

$inputPopup.each(function() {
    if(this.value !== '') {
        $(this).parent().addClass(filledClassPopup);
    }
});

$inputPopup.change(function() {
    $inputPopup.each(function() {
        if(this.value !== '') {
            $(this).parent().addClass(filledClassPopup);
        }
    });
});

$inputPopup.focus(onInputFocusPopup);
$inputPopup.blur(onInputBlurPopup);
