import "./login.scss";

const loginBoxButton = document.querySelector('.js-login-box-click');
const loginBox = document.querySelector('.js-login-box');
const closeBtnLoginBox = document.querySelector('.js-login-box-close');
const nameHidden = 'is-hidden';

const toggleLoginBox = (e) => {
    e.stopImmediatePropagation();

    if(loginBox.classList.contains(nameHidden)) {
        loginBox.classList.remove(nameHidden);
        bodyCloseLoginBox();
    } else {
        loginBox.classList.add(nameHidden);
        removeBodyCloseLoginBox()
    }
}

const closeLoginBox = () => {
    loginBox.classList.add(nameHidden);
    removeBodyCloseLoginBox()
}

// Events Document
const bodyCloseLoginBox = function(e) {
    document.addEventListener('click', closeLoginBox)
}
const removeBodyCloseLoginBox = function(e) {
    document.removeEventListener('click', closeLoginBox)
}
//Events Login Box
const addLoginBoxListeners = function() {
    if(loginBoxButton) {
        loginBox.addEventListener('click', function(e){
            e.stopImmediatePropagation();
        });
        loginBoxButton.addEventListener('click', toggleLoginBox);
        closeBtnLoginBox.addEventListener('click', closeLoginBox);
    }
}

//Init
addLoginBoxListeners();

