import 'lightgallery/lightgallery.js';

lightGallery(document.getElementById('aniimated-thumbnials'), {

});

lightGallery(document.querySelector('.samples-page__list'), {
    selector: '.samples-page__list-item'
});

lightGallery(document.getElementById('news-paper'), {

});