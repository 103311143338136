// Styles
import 'styles/styles.scss'

// Scripts
import 'slick'
//import 'all-js'
import 'all-js/placeholder-input'
import 'lightgallery'
import 'header/login/login'
import 'mainmenu/mainmenu'
import 'cv-samples/cv-samples'
import 'popup-level/popup-level'
import 'counter-up'


// import 'subscribe/subscribe'