import './popup-level.scss';
import $ from 'jquery';

//=============================
const $body = $('body');
const $popupBox = $('#popup-tmp');
const $tplPopup = $('.popup-level-content-tpl');
const $btnShow = $('.js-popup-btn-show');

const animateClassName = 'popup-level--animate';
const animateTime = 300;

//==============================
const closePopup = function(popup) {
    popup.find('.popup-level').removeClass(animateClassName);
    setTimeout(function() {
        popup.remove();
    }, animateTime);
};
const showPopup = function(popup) {
    $body.append(popup);
    setTimeout(function(){
        popup.find('.popup-level').addClass(animateClassName);
    }, 50);
};

const createPopup = function(element){
    const popup = $($popupBox.html());
    const idContent = $(element).data('popup-id');
    const content = $('#' + idContent).html();

    popup.find('.js-popup-level__container').html(content);

    return popup;
};

$btnShow.click(function() {
    const popup = createPopup(this);
    const closeArea = popup.find('.popup-level-back, .popup-level__close');

    showPopup(popup);

    closeArea.click(function() {
        closePopup(popup);
    })

});
