import $ from 'jquery';

/*
    @Init = Not initial if has CSS-class '.js-not-menu-slick'
*/

export const menuSlickInit = function () {

    const initClass  = $('.js-not-menu-slick');

    if(initClass.length) {
        return false;
    }

    const menu = $('.js-menu-slick');
    const header = $('.header');
    const offsetTop = menu.offset().top;
    const wind = $(window);
    let markSlick = true;
    let menuScrollTop = 0;
    let isSlick = false;
    let widthWin = wind.outerWidth();
    let markResize = true;
    let timeout;

    window.addEventListener('resize', () => {
        if (markResize) {
            widthWin = wind.outerWidth();

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                markResize = true;
            }, 400);
            markResize = false;
        }

    });

    window.addEventListener('scroll', () => {

        if (widthWin >= 1200) {
            menuScrollTop = wind.scrollTop();
            isSlick = menuScrollTop >= offsetTop;

            if (isSlick && markSlick) {
                menu.addClass('is-slick');
                markSlick = false;
            } else if (!isSlick && !markSlick) {
                menu.removeClass('is-slick add');
                markSlick = true;
            }
        } else {
            menuScrollTop = wind.scrollTop();
            isSlick = menuScrollTop >= offsetTop;

            if (isSlick && markSlick) {
                header.addClass('is-slick');
                markSlick = false;
            } else if (!isSlick && !markSlick) {
                header.removeClass('is-slick add');
                markSlick = true;
            }
        }

    });

};
