import $ from 'jquery';
import './slick.js';

$(function () {


    $('.packages-page-items-pack').slick({
        infinite: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        speed: 300,
        touchThreshold: 10,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: "slick"
            }
        ]
    });

    $('.packages-levels-items').slick({
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        speed: 300,
        touchThreshold: 10,
        responsive: [
            {
                breakpoint: 5000,
                settings: "unslick"
            },
            {
                breakpoint: 991,
                settings: {
                    centerMode: true,
                    centerPadding: '200px',
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });



    $('.benefit__wrp').slick({
        slidesToShow: 3,
        draggable: false,
        infinite: false,
        speed: 300,
        touchThreshold: 10,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    vertical: false,
                    dots: true,
                    // centerMode: true,
                    // centerPadding: '10px',
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.samples-page__benefit-wrapper').slick({
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        mobileFirst: true,
        arrows: false,
        vertical: false,
        dots: true,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        speed: 300,
        touchThreshold: 10,
        responsive: [{
            breakpoint: 767,
            settings: "unslick"
        }]
    });

    $('.testimonials-slider__wrp').slick({
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        speed: 300,
        touchThreshold: 10,
    });
    $('.partners__wrp').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: true,
        arrows: false,
        speed: 300,
        touchThreshold: 10,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }

        ]

    });
    $('.packages__slider').slick({
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        slidesToShow: 1,
        speed: 300,
        touchThreshold: 10,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    dots: true,
                    centerPadding: '10px',
                    slidesToShow: 1
                }
            }
        ]
    });
});
