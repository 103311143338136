import $ from 'jquery';
import { menuSlickInit } from './mainmenu.slick';

menuSlickInit();

const menuBtn = $('.button__show-menu');
const mainMenu = $('.js-menu-mobile');
const signBlock = $('.js-login-box');
const subHeaderMenuCustomer = $('.js-sub-header-menu-customer');
const mainMenuNav = $('.js-main-menu');
const menuDropdown = $('.main-menu__dropdown');

// Buttons
const btnMenuClose = $('.js-sub-header-menu-close');
const btnSubMenuOpen = $('.main-menu__link.active');
const btnSubMenuClose = $('.menu-button-back');
const btnSignMenu = $('.js-btn-sign-mob-head');
const btnSignShowMenu = $('.js-login-box-show-menu');
const btnShowMenuCustomer =  $('.js-show-sub-header-menu-customer');
const btnShowMainMenuMobile = $('.js-login-box-show-main-menu');
const btnBlockSignMobile = $('.js-btn-signin-mobile');

const menuNameToggleClass = 'is-mobile-show';
const signBlockToggleClass = 'is-hidden';
const isHiddenClass = 'is-hidden';
const isHiddenSubMenuClass = 'is-hidden';

// Main Menu
const closeMainMenu = function() {
    mainMenu.removeClass(menuNameToggleClass);
};
const showMainMenu = function() {
    mainMenu.addClass(menuNameToggleClass);
};
const showSignBlock = function() {
    signBlock.removeClass(signBlockToggleClass);
};
const hideSignBlock = function() {
    signBlock.addClass(signBlockToggleClass);
};
const showCustomerMenu = function() {
    btnBlockSignMobile.addClass(isHiddenClass);
    mainMenuNav.addClass(isHiddenClass);
    subHeaderMenuCustomer.removeClass(isHiddenClass);
    btnShowMainMenuMobile.removeClass(isHiddenClass);
};
const showMainMenuMobile = function() {
    btnBlockSignMobile.removeClass(isHiddenClass);
    mainMenuNav.removeClass(isHiddenClass);
    subHeaderMenuCustomer.addClass(isHiddenClass);
    btnShowMainMenuMobile.addClass(isHiddenClass);
};
const showSubmenuMobile = function() {
    menuDropdown.addClass(isHiddenSubMenuClass);
    mainMenuNav.addClass('is-show-drop');
};
const hideSubmenuMobile = function () {
    menuDropdown.removeClass(isHiddenSubMenuClass);
    mainMenuNav.removeClass('is-show-drop');
};

// === Events ===
menuBtn.click(function() {
    showMainMenu();
});
btnSignShowMenu.click(function() {
    showMainMenu();
    hideSignBlock();
});
btnSubMenuOpen.click(function () {
    showSubmenuMobile();
});
btnSubMenuClose.click(function () {
    hideSubmenuMobile();
});
// Event close
btnMenuClose.click(function() {
    closeMainMenu();
});

//
btnSignMenu.click(function() {
    closeMainMenu();
    showSignBlock();
});
btnShowMenuCustomer.click(function(){
   showCustomerMenu();
   return false;
});
btnShowMainMenuMobile.click(function(){
    showMainMenuMobile();
});

$(document).ready(function(){
    $('.services-items-arrow').on('click', function (e) {
        e.preventDefault();
        var id  = $(this).attr('href'),
        top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 1000);
    });
});
