import $ from 'jquery'
import waypoints from '../waypoints/waypoints.min'
waypoints($)
import './jquery.counterup.min'
// var waypoint = new Waypoint({
//     element: document.getElementById('waypoint'),
//     handler: function(direction) {
//         console.log('Scrolled to waypoint!')
//     }
// })
// window.onload = function () {
//     $("#benefit_id").waypoint(function() {
//         $(".benefit__item").addClass('animated');
//
//     }, { offset: '90%'});
//
//     $("#samples-id").waypoint(function() {
//         $(".samples-page__benefit-item").addClass('animated');
//
//     }, { offset: '90%'});
//
//     $("#how-works").waypoint(function() {
//         $(".how-works-item_1").addClass('animated');
//         $(".how-works-item_3").addClass('animated');
//
//     }, { offset: '90%'});
// }

$(function () {
    $("#benefit_id").waypoint(function() {
        $(".benefit__item").addClass('animated');

    }, { offset: '90%'});

    $("#samples-id").waypoint(function() {
        $(".samples-page__benefit-item").addClass('animated');

    }, { offset: '90%'});

    $("#how-works").waypoint(function() {
        $(".how-works-item_1").addClass('animated');
        $(".how-works-item_3").addClass('animated');

    }, { offset: '90%'});


    $('.site-activity__number span').counterUp({
        delay: 10,
        time: 1000
    });
})


